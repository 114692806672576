import './App.css';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Contato from './pages/Contato';
import Dashboard from './pages/Dashboard';
import Navbar from './components/Navbar';
import Container from './layout/Container';
import Footer from './layout/Footer';
import Contact from './components/contact';
import Register from './pages/Register';

function AppContent() {
  const location = useLocation();
  const isDashboard = location.pathname === '/login';
  const isRegister = location.pathname === '/register';

  return (
    <div className={`App ${isDashboard ? 'dashboardBackground' : ''}`}>
      {!isDashboard && !isRegister && <Contact />}
      {!isDashboard && !isRegister && <Navbar />}
      <Container customClass="min-height">
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route path="/login">
            <Dashboard/>
          </Route>
          <Route path="/register">
            <Register/>
          </Route>
          <Route path="/contato">
            <Contato/>
          </Route>
        </Switch>
      </Container>
      {!isDashboard && !isRegister && <Footer />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
