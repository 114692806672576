import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './LoginDashboard.module.css';
import { toast, ToastContainer } from 'react-toastify';

const FormFaturas = () => {
  const [cpfcnpj, setCpfCnpj] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleLogin = async () => {
    try {
      const response = await fetch('https://104.234.189.45:3001/api/login', { // Substitua pela URL da sua API
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cpfcnpj, password }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('Login bem-sucedido! Redirecionando...');
        // Armazene o token no localStorage ou em um state management
        localStorage.setItem('token', data.token);

        setTimeout(() => {
          history.push('/dashboard'); // Redireciona para o dashboard após o login
        }, 5000);
      } else {
        toast.error(`Erro: ${data.error}`);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      toast.error('Erro ao tentar logar. Tente novamente.');
    }
  };

  return (
    <div className="col-md-6 xs-hide">
      <div className="form-container">
        <div className={styles.form}>
          <div className={styles.formgroup}>
            <p className={styles.formtitle}>Área do Cliente</p>
            <span>Faça login na sua conta para começar a usar a CarinaPaga.</span>
            <div className={styles.inputgroup}>
              <input
                name="cpfcnpj"
                placeholder="CPF/CNPJ"
                id="cpfcnpj"
                className={styles.input}
                value={cpfcnpj}
                onChange={(e) => setCpfCnpj(e.target.value)}
                required
              />
              <input
                name="password"
                placeholder="Senha"
                id="password"
                className={styles.input}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="button"
              className={styles.button}
              onClick={handleLogin}
              style={{ paddingRight: '8rem', paddingLeft: '8rem' }}
            >
              ACESSAR
            </button>
          </div>
          <p className={styles.agreementtext}>
            Se não tem conta, <a href="/register" className={styles.registerLink}>registre-se aqui</a>.
          </p>
          <p className={styles.agreementtext}>
            Esqueci a <a href="/register" className={styles.registerLink}>minha senha</a>.
          </p>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default FormFaturas;
