import React from 'react';
import './services.css'; // Certifique-se de que o arquivo CSS está sendo importado
import iconTick from './assets/img/icons/icon-tick.png'; // Importe a imagem diretamente

const services = [
    { name: "Multas", imgSrc: iconTick },
    { name: "IPVA", imgSrc: iconTick },
    { name: "Licenciamento", imgSrc: iconTick },
    { name: "ITBI/ITCD", imgSrc: iconTick },
    { name: "IPTU", imgSrc: iconTick },
    { name: "Débitos CREA/SP - EM BREVE", imgSrc: iconTick },
    { name: "Guias de arrecadação", imgSrc: iconTick },
    { name: "Boletos diversos", imgSrc: iconTick }
  ];

const App = () => {
  return (
    <div className="mr-auto grid sm:grid-cols-4 lg:grid-cols-4 xl:max-w-[90%]" style={{marginLeft: 50}}>
      {services.map((service, index) => (
        <div key={index} className="flex flex-col my-2 shadow-lg bg-white xl:w-[8rem] xl:h-[9rem] 2xl:w-[9rem] 2xl:h-[10rem] relative items-center justify-center">
          <img className="mx-auto p-4" src={service.imgSrc} alt={service.name} />
          <div className="info-box break-words text-xs text-center flex p-1 font-semibold text-gray-500 bg-white">
            <span className="font-bold mx-auto">{service.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default App;
