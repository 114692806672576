// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__oppbQ {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
}

.Container_min-height__dZre0 {
    min-height: 75%;
}`, "",{"version":3,"sources":["webpack://./src/layout/Container.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container {\r\n    width: 1200px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin: 0 auto;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.min-height {\r\n    min-height: 75%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__oppbQ`,
	"min-height": `Container_min-height__dZre0`
};
export default ___CSS_LOADER_EXPORT___;
