import React, { useState } from 'react';
import styles from './Dashboard.module.css';
import FormDashboard from '../components/LoginDashboard';

function Dashboard() {
    // Estado para controlar o texto ativo
    const [activeText] = useState(1); // Se for questão veicular altere aqui para 2, caso seja só pagamento de faturas altere para 1;

    return (
        <div className={styles.dashboardContainer}>
            <div className="row align-items-center">
                <div className="col-md-6">
                    {/* Renderiza o primeiro ou segundo texto com base no estado */}
                    {activeText === 1 ? (
                        <h1 className={styles.heading}>
                            Agora, pagar suas faturas com o <span className={styles.highlight}>CarinaPaga</span> ficou ainda mais fácil: <br/>parcele em até 12x no <span className={styles.highlight}>cartão de crédito!</span>
                        </h1>
                    ) : (
                        <h1 className={styles.heading}>
                            Pague seus <span className={styles.highlight}>débitos veiculares</span> com rapidez e segurança, parcelando em <span className={styles.highlight}>até 12x no cartão!</span>
                        </h1>
                    )}
                    {/* <img src={Seguranca} alt="Medalha de Segurança" className={styles.securityImage} /> */}
                </div>
                <FormDashboard />
            </div>

            <div className="spacer-10"></div>
        </div>
    );
}

export default Dashboard;
