import React, { useState } from 'react';
import ModalComponent from './Modal';
import styles from './FormFaturas.module.css';

const FormFaturas = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [billType, setBillType] = useState('');

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmit = () => {
    openModal();
  };

  return (
    <div className="col-md-6 xs-hide">
      <div className={styles.form}>
        <div className={styles.formgroup}>
          <p className={styles.formtitle}>Realize uma consulta da sua fatura sem compromisso!</p>
          <div className={styles.inputgroup}>
            <select 
              name="billType" 
              id="billType" 
              className={styles.input} 
              required 
              value={billType} 
              onChange={(e) => setBillType(e.target.value)}
            >
              <option value="">Selecione o tipo de conta</option>
              <option value="agua">Água</option>
              <option value="energia">Energia</option>
              <option value="outros">Outros Boletos</option>
              <option value="debitosveiculares">Débitos Veículares</option>
            </select>
            <input 
              name="email" 
              placeholder="Seu melhor e-mail" 
              id="email" 
              className={styles.input} 
              required 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
            />
            <input 
              name="phone" 
              placeholder="Seu melhor telefone" 
              id="phone" 
              className={styles.input} 
              required 
              value={phone} 
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <button type="button" className={styles.button} onClick={handleSubmit}>
            Consulte Grátis
          </button>
        </div>
        <p className={styles.agreementtext}>Ao continuar você está de acordo com o nosso termos de uso e política de privacidade.</p>
      </div>

      {/* Renderizar o modal e passar as informações como props */}
      <ModalComponent 
        isOpen={modalIsOpen} 
        closeModal={closeModal} 
        email={email} 
        phone={phone} 
        billType={billType} 
      />
    </div>
  );
}

export default FormFaturas;
