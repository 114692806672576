import { Link } from 'react-router-dom'
import styles from './Navbar.module.css'
import Container from '../layout/Container'
import Button from '@mui/material/Button';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

function Navbar() {
    return (
        <nav className={styles.navbar}>
            <Container>
                <Link to="/">
                   <h1>CarinaPaga</h1>
                   {/* <p className="slogan">A solução chegou!</p> */}
                </Link>
                <ul className={styles.list}>
                    <li className={styles.item2}>
                    <Link to="/">Início</Link>
                    </li>
                    <li className={styles.item2}>
                    <Link to="/">Serviços</Link>
                    </li>
                    <li className={styles.item2}>
                    <Link to="/">Ajuda</Link>
                    </li>
                    <li className={styles.item2}>
                    <Link to="/">Dúvidas Frequentes</Link>
                    </li>
                </ul>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                        <Link to="/login"><Button style={{background: "rgb(255, 255, 255)", color: "#000", border: "1px solid #e63888", borderRadius: '5px'}}>
                       Acompanhar pedidos <ArrowCircleRightIcon style={{}}/>
                        </Button></Link>
                        </li>
                    </ul>
            </Container>
        </nav>
    )
}
export default Navbar