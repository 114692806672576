// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-header-custom {
  background-color: #e20055;
  color: #fff;
  font-weight: bold; /* Adiciona negrito ao texto */
}

.accordion-body-custom {
  background-color: #fff;
  color: #000;
  font-weight: normal; /* Mantém o texto no corpo sem negrito, se desejar */
}
  
  .accordion-item-custom {
    border: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/FAQAccordion.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,iBAAiB,EAAE,8BAA8B;AACnD;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,mBAAmB,EAAE,oDAAoD;AAC3E;;EAEE;IACE,YAAY;EACd","sourcesContent":[".accordion-header-custom {\r\n  background-color: #e20055;\r\n  color: #fff;\r\n  font-weight: bold; /* Adiciona negrito ao texto */\r\n}\r\n\r\n.accordion-body-custom {\r\n  background-color: #fff;\r\n  color: #000;\r\n  font-weight: normal; /* Mantém o texto no corpo sem negrito, se desejar */\r\n}\r\n  \r\n  .accordion-item-custom {\r\n    border: none;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
