// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Adicionando a propriedade background-size para tornar o background responsivo */
.App {
  text-align: center;
  background: transparent; /* Ou remova esta linha se não for necessário */
  height: auto;
  width: 100%;
  /* background-size: cover; /* O background cobrirá toda a área visível */
  /* background-position: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Adicionando regras de mídia para tornar o layout responsivo */
@media only screen and (max-width: 768px) {
  .App-header {
    padding: 20px; /* Adicionando espaçamento interno para dispositivos móveis */
  }

  .App-logo {
    height: 30vmin; /* Reduzindo o tamanho do logo para dispositivos móveis */
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,kFAAkF;AAClF;EACE,kBAAkB;EAClB,uBAAuB,EAAE,+CAA+C;EACxE,YAAY;EACZ,WAAW;EACX,wEAAwE;EACxE,iCAAiC;AACnC;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA,gEAAgE;AAChE;EACE;IACE,aAAa,EAAE,6DAA6D;EAC9E;;EAEA;IACE,cAAc,EAAE,yDAAyD;EAC3E;AACF","sourcesContent":["/* Adicionando a propriedade background-size para tornar o background responsivo */\n.App {\n  text-align: center;\n  background: transparent; /* Ou remova esta linha se não for necessário */\n  height: auto;\n  width: 100%;\n  /* background-size: cover; /* O background cobrirá toda a área visível */\n  /* background-position: center; */\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n/* Adicionando regras de mídia para tornar o layout responsivo */\n@media only screen and (max-width: 768px) {\n  .App-header {\n    padding: 20px; /* Adicionando espaçamento interno para dispositivos móveis */\n  }\n\n  .App-logo {\n    height: 30vmin; /* Reduzindo o tamanho do logo para dispositivos móveis */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
