import React, { useState } from 'react';
import styles from './Home.module.css';
import Seguranca from '../assets/medalhaseguranca.png';
import FAQAccordion from "../components/FAQAccordion";
import Services from "../components/services";
import FormFaturas from '../components/FormFaturas';

function Home() {
    // Estado para controlar o texto ativo
    const [activeText] = useState(1); // Se for questão veícular altere aqui para 2, caso seja só pagamento de faturas altere para 1;

    return (
        <div className={styles.body}>
            <div className="row align-items-center">
                <div className="col-md-6">
                    {/* Renderiza o primeiro ou segundo texto com base no estado */}
                    {activeText === 1 ? (
                        <h1 style={{ fontWeight: "700", fontSize: "48px", lineHeight: "3rem", color: "#1f2029", fontFamily: "Roboto", fontStyle: 'normal' }}>
                            Pague suas faturas de água, energia e muito mais em até <span style={{ color: "#e63888" }}>12x</span> no <span style={{ color: "#e63888" }}>cartão de crédito!</span>
                        </h1>
                    ) : (
                        <h1 style={{ fontWeight: "700", fontSize: "48px", lineHeight: "3rem", color: "#1f2029", fontFamily: "Roboto", fontStyle: 'normal' }}>
                            Pague seus <span style={{ color: "#e63888" }}>débitos veiculares</span> com rapidez e segurança, parcelando em <span style={{ color: "#e63888" }}>até 12x no cartão!</span>
                        </h1>
                    )}
                    <p className="font-weight-bold" style={{ color: "#000826", fontWeight: '400', fontSize: '1.5rem', lineHeight: '1.75rem' }}>
                        Aproveite a conveniência e facilidade de pagamento parcelado para manter suas contas em dia sem comprometer seu orçamento.
                    </p>
                    <img src={Seguranca} alt="" style={{ width: '100px', marginBottom: "-8rem", marginTop: "-1rem" }} />
                </div>
                <FormFaturas />
            </div>

            <div className="spacer-10"></div>
            <div className="row align-items-center">
                <div className="m-4"></div>
                <h1 style={{ fontSize: 35, color: "#000", marginTop: 35 }}>
                    Pague com a <a className={styles.parcelbutton} href='/'>CarinaPaga</a> com muita facilidade
                </h1>
                <p className="font-weight-bold" style={{ color: "#000826", fontWeight: '400', fontSize: '1.0rem', lineHeight: '1.75rem' }}>
                    Aproveite a conveniência e facilidade de pagamento parcelado para manter suas contas em dia sem comprometer seu orçamento.
                </p>
                <Services />
                <h1 style={{ fontSize: 35, color: "#000", marginTop: 35, marginBottom: 35 }}>
                    Está com alguma<a className={styles.parcelbutton} href='/'>dúvida?</a><br />Veja nossas perguntas frequentes:
                </h1>
                <FAQAccordion />
                <div className="m-2"></div>
            </div>
        </div>
    );
}

export default Home;
